import sizes from "../scss/_settings/_settings.scss";
window.sizes = sizes;

require('../scss/main.scss');

import 'owl.carousel'

require('../components/jquery.maskedinput');

require('./parts/base.js');
require('./parts/cart.js');
require('./parts/product.js');
require('./parts/product-list.js');
require('./parts/checkout.js');
//require('./parts/checkout.back.js');
// require('./parts/search.js');
require('./parts/map.js');
require('./parts/image-loader.js');

// require('../fonts/Merriweather/face.css');
require('../fonts/Ubuntu/face.css');
