$(function () {

  let timer = null;

  let cart_updated = function (ret) {
    $('[data-total-price]').html(ret.total == 0 ? "" : rub(ret.total));
    $.get('', {}, (page) => {
      let EL = $(`<div>${page}</div>`).find('[data-refresh]');
      EL.each((k) => { $('[data-refresh="'+EL[k].dataset['refresh']+'"]').html(EL[k].innerHTML); });
      $(document).trigger('cartUpdated');
    });
  };

  let update = function (item, count) {
    if (!count || isNaN(count)) count = 1;
    if (count < 1) count = 1;
    if (count > 999) count = 999;
    item.find('[data-count]').val(count);

    let sum = item.find('[data-sum-item]');
    sum.html(rub(parseInt(sum.data('sum-item')) * count));

    if (timer) clearTimeout(timer);
    timer = setTimeout(function () {
      request('/cart/set', {
        pid: item.data('id'),
        inf: item.data('inf'),
        count: count
      }, cart_updated);
    }, 350);
  };

  $(document).on('change', 'input.form-saver', function () {
    localStorage.setItem('saver-' + $(this).attr('name'), $(this).val());
  });

  $('input.form-saver').each(function () {
    $(this).val(localStorage.getItem('saver-' + $(this).attr('name') || ''));
  });

  $(document).on('click', '[data-remove]', function () {
    let item = $(this).closest('.item');
    request('/cart/set', {
      pid: item.data('id'),
      inf: item.data('inf'),
      count: 0
    }, cart_updated);
    item.addClass('fade-out');
    setTimeout(function () {
      item.remove();
      if ($('.cart-products .item').length === 0) location.reload();
    }, 500);
  });

  $(document).on('click', '[data-set-count]', function () {
    let item = $(this).closest('.item');
    let counter = item.find('[data-count]');
    let count = parseInt(counter.val()) + parseInt($(this).data('set-count'));
    update(item, count);
  });

  $(document).on('keyup change', '[data-count]', function () {
    let item = $(this).closest('.item');
    let count = parseInt($(this).val());
    update(item, count);
  });

  // Add to cart action
  $(document).on('click', '[data-add-to-cart]', function () {
    let pid = $(this).data('add-to-cart');
    $('.body').addClass('alert-visible success-visible');
    let val = $('[data-art]:checked').val();

    goal('knopka_v_korziny');
    request('/cart/add', {pid: pid, val: val}, cart_updated);
  });

  // Add to cart close alert
  $(document).on('click', '[data-alert-hide]', function () {
    $('.body').removeClass('alert-visible quick-visible');
  });

  // Quick order action
  let PID = 0;
  let PHONE = "?";
  $(document).on('click', '[data-one-click]', function () {
    $('.body').addClass('alert-visible quick-visible');
    PID = $(this).data('one-click');
    goal('quick_order_init');
  });

  $(document).on('keyup change', '[data-quick-phone]', function () {
    if ($(this).hasClass('completed')) {
      $('[data-make-order-quick]').removeClass('disabled');
      PHONE = $(this).val();
    } else {
      $('[data-make-order-quick]').addClass('disabled');
    }
  });

  $(document).on('click', '[data-make-order-quick]:not(.disabled)', function () {
    $('[data-done-buttons]').remove();
    $('[data-done]').html('Оформление заказа ...');
    goal('oformit_zakaz'); // quick_order_maked

    request('/cart/quick', {
      phone: PHONE, pid: PID
    }, function () {
      $('[data-done]').html('Заказ оформлен. Ожидайте звонка!');
      setTimeout(location.reload, 5000);
    });
  });
  
  // UPD
  $('[data-col-points] .place').click(function(){
    $('[data-col-points] .place, [data-col-points] .frame').removeClass('active');
    let pid = $(this).addClass('active').data('id');
    $(`#${pid}`).addClass('active');
  });
  
  $('.selectors [data-tab]').click(function(){
    let nm = $(this).data('tabname');
    let tt = $(this).data('tab');
    
    $(`[data-tabname="${nm}"]`).removeClass('current');
    $(`[data-tabname="${nm}"][data-tab="${tt}"]`).addClass('current');
    $('[data-in]').removeClass('visible').each(function (){
      if ($(this).data('in').indexOf(tt) != -1) $(this).addClass('visible');
    })
    
  });
  
  // DEFAULT ul
  $('[data-in]').removeClass('visible').each(function (){
    if ($(this).data('in').indexOf('ul') != -1) $(this).addClass('visible');
  })
  
  
});