window.PVZ = undefined;

$(function () {
    if ($('[data-full-map]').length === 0) return ;

    let loadedPoints = {};
    let order = { zip: 110000, center: $('[data-full-map]').data('full-map') };

    let pointsToMap = function () {
        if (!window.ymap) return;

        console.log('pointsToMap');
        console.log(loadedPoints);

        let cluster = new ymaps.Clusterer({ clusterDisableClickZoom: false });
        let myGeoObjects = [];

        let offset = function (to) {
            let ofs = parseFloat(to) + (Math.random() * 0.0007 - 0.00035);
            console.log(to + ' -> ' + (ofs));
            return ofs;
        };

        for (let i in loadedPoints) {
            let point = loadedPoints[i];
            if (point.onMap) continue;

            let by_cart = point.by_cart == 0 ? '<div className="pay-box pay-cache"></div>' : '';

            let mark = [
                '<div class="placemark">',
                '<img src="/static/images/'+point.type+'-logo.png">',
                '<div class="mark-price" data-mark-type="'+point.type+'" data-mark-code="'+point.code+'">'+point.price+' ₽</div>',
                by_cart,
                '</div>'
            ].join('');

            myGeoObjects.push(new ymaps.Placemark([offset(point.lat), offset(point.lng)], {
                balloonContent: point.address + '<br><strong>' + point.work + '</strong>',
                ID: point.ID
            }, {
                iconLayout: ymaps.templateLayoutFactory.createClass(mark),
                iconShape: { type: 'Circle', coordinates: [0, 0], radius: 20 }
            }));

            loadedPoints[i].onMap = true;
        }

        cluster.add(myGeoObjects);

        window.ymap.geoObjects.removeAll();
        window.ymap.geoObjects.add(cluster);

        $('[data-map-container]').addClass('loaded');

        window.ymap.geoObjects.events.add('click', function (e) {
            let ID = e.get('target').properties.getAll()['ID'];
            if (!loadedPoints[ID]) return ;

            e.preventDefault();

            $('[data-map-container]').addClass('point-active');

            let point = loadedPoints[ID];
            window.PVZ = point;

            $('[name="address"]').trigger('change');

            $('[data-p-by-cart]').hide();
            if (point.by_cart == 0) $('[data-p-by-cart]').show();

            $('[data-p-type]').html(point.type);
            $('[data-p-address]').html(point.address + (point.comment ? ('<br><span class="helper">' + point.comment + '</span>') : ''));
            $('[data-p-work]').html(point.work);
            $('[data-p-phone]').html(point.phone);

            $('[data-service="points"]').html(point.price + ' ₽');
        });
    };

    let reloadPrices = null;

    let load = setInterval(function () {
        if (!window.ymaps || !window.ymaps.Map) return ;

        window.ymap = new ymaps.Map('map', { center: order.center, zoom: 12, controls: [] });
        ymap.controls.add("zoomControl", { position: {top: 15, left: 15} });
        // ymap.on('drag', console.log);
        // ymap.behaviors.disable('scrollZoom');
        ymap.events.add('actionend', function (e) {
            order.center = e.originalEvent.map.getCenter();
            clearTimeout(reloadPrices);
            reloadPrices = setTimeout(getPrices, 1500)
        });
        
        clearInterval(load);
        pointsToMap();
    }, 100);

    let getPrices = (function () {
        let process = false;
        console.log('-> getPrices', getPrices);

        return function () {
            $('[data-map-container]').removeClass('loaded');

            if (process) process.abort();

            process = request('/cart/prices', order, function (ret) {
                for (let i in ret.points) loadedPoints[ret.points[i].ID] = ret.points[i];
                pointsToMap();
            });
        };
    }());

    getPrices();
    
    $(document).on('click', '[data-close-point]', function () {
        window.PVZ = undefined;
        $('[data-map-container]').removeClass('point-active');
        $('input').trigger('pointClose');
    });
});
