$(function () {
    // Products Price Filter
    let numeric = function (input) {
        let value = parseInt(input.val());
        if (value < 0 || isNaN(value)) {
            value = 0;
            input.val("");
        }
        return value;
    };

    $(document).on('keyup change', '[data-filter]', function () {
        let min = numeric($('[data-filter="min"]'));
        let max = numeric($('[data-filter="max"]'));

        let m = 0;
        let k = $('[data-sort].active').data('sort');
        if (k === "asc") m = -1;
        if (k === "desc") m = 1;

        $('[data-price]').each(function () {
            let price = parseInt(this.dataset.price);
            this.style.order = m * price;

            $(this).show();
            if (price < min) $(this).hide();
            if (max >= min && max > 0 && price > max) $(this).hide();
        });
    });

    $(document).on('click', '[data-sort]', function () {
        $('[data-sort]').removeClass('active');
        $(this).addClass('active');
        $('[data-filter]').trigger('change');
    });


    // Products Slider
    let responsive = {};

    responsive[0]       = {items: 1};
    responsive[sizes.S] = {items: 2};
    responsive[sizes.M] = {items: 3};
    responsive[sizes.L] = {items: 3};

    $('[data-carousel]').owlCarousel({
        items: 4,
        margin: 30,
        nav: true,
        responsive: responsive
    });
});

