$(function () {
  let order = {
    request: false,
  };

  /* --------------------------------------------------------------------- */
  // Validator:
  setInterval(function () {
    let result = (function () {
      let form = $('[data-checkout-form] input, [data-checkout-form] textarea');
      form.each((k) => { order[form[k].name] = form[k].value; });
      order.client_type = $('[data-tabname="client"].current').data('tab');
      order.delivery_type = $('[data-tabname="delivery"].current').data('tab');
  
      if (!order.f || !order.i) return { error: 'Введите, пожалуйста, Имя и Фамилию' };
      if (!$('[name="phone"]').hasClass('completed')) return { error: 'Введите, пожалуйста, номер телефона' };

      // Клиент
      if (order.client_type === 'fl') {
      }
      if (order.client_type === 'ul') {
        if (!order.inn || !order.kpp) return { error: 'Введите, пожалуйста, ИНН и КПП' };
      }
      if (order.client_type === 'ip') {
        if (!order.inn) return { error: 'Введите, пожалуйста, ИНН' };
        if (!order.company) return { error: 'Введите, пожалуйста, Название компании' };
      }
  
      // Доставка
      if (order.delivery_type === 'sf') {
        order.delivery_office = $('[data-place-sf].active').data('id');
      }
      if (order.delivery_type === 'cu') {
        if (order.delivery.length < 5) {
          return { error: 'Введите, пожалуйста, адрес доставки' };
        }
      }
      if (order.delivery_type === 'pt') {
        order.pvz = window.PVZ;
        if (!order.pvz) return { error: 'Выберите точку самовывоза' };
      }

      console.log('VALIDATE', order);
      return true;
    })();

    if (result.error) {
      $('[data-make-order]').attr('title', result.error).addClass('disabled');
      $('[data-validator]').html(result.error);
    } else {
      $('[data-make-order]').attr('title', '').removeClass('disabled');
    }
  }, 350);

  /* --------------------------------------------------------------------- */
  // Make Order:
  $(document).on('click', '[data-make-order]:not(.disabled)', function () {
    if (order.request) return ;
    order.request = true;
    $('[data-make-order]').html('Оформление ...');
    goal('start_order');
    request('/cart', order, function (ret) {
      if (ret.code) {
        goal('oformit_zakaz');
        return setTimeout(() => {
          location.href = '/order/' + ret.code;
        }, 300);
      }
      location.reload();
    });
  });
});
