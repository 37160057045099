$(function () {
  // Image gallery
  $('[data-gallery-item]:first').addClass('current');

  $(document).on('click', '[data-gallery-item]', function () {
    if (window.isloading > 0) return ;
    $('[data-gallery-item]').removeClass('current');
    $(this).addClass('current');
    window.ImageX($('[data-gallery]'), $(this).data('open'));
  });


  //data-img
  let currentID = 0;

  function color(ID) {
    $('[data-gallery-item][data-art]').removeClass('current').hide();
    $('[data-gallery-item][data-art="'+ID+'"]').show();
    currentID = ID;
    window.ImageX($('[data-gallery]'), $('[data-gallery-item][data-art="'+ID+'"]:first').addClass('current').data('open'));
    $('label[data-art]').removeClass('current');
    let art = $('label[data-info-id="'+ID+'"]').addClass('current').data('art');
    $('[data-art-v]').html(art);
  }

  $('label[data-art]').click(function () {
    color($(this).data('info-id'));
  });

  color($('label[data-art]').data('info-id'));

  $(document).on('click', '[data-img="-1"]', function () {
    let next = $('[data-gallery-item].current').prev('[data-art="'+currentID+'"]');
    if (next.length === 0) next = $('[data-gallery-item][data-art="'+currentID+'"]:last');
    next.trigger('click');
  });

  $(document).on('click', '[data-img="+1"]', function () {
    let next = $('[data-gallery-item].current').next('[data-art="'+currentID+'"]');
    if (next.length === 0) next = $('[data-gallery-item][data-art="'+currentID+'"]:first');
    next.trigger('click');
  });

  $('[data-stars]').each(function () {
    let stars = $(this);
    let initial = this.dataset['stars'];

    let fill = function (val) {
      stars.find('.one-star').removeClass('fill').each(function (n, i) {
        if (n + 1 <= val) $(this).addClass('fill');
      });
    };

    if (this.dataset['owner']) {
      fill(this.dataset['owner']);
      stars.find('.stars-rate').addClass('voted');
    } else {
      fill(this.dataset['stars']);
    }

    stars.find('.one-star').hover(function () {
      fill(this.dataset['val']);
    }, function () {
      fill(initial);
    });

    stars.find('.one-star').click(function () {
      let vote = $(this).index() + 1;
      stars.find('.stars-rate').addClass('voted');
      initial = vote;

      fill();

      $.post('/rate-object', $.extend(window._token, {
        rate: vote,
        to: stars.data('object')
      }), function (res) {
        stars.find('[data-total]').html(res.total);
        stars.find('[data-mean]').html(res.mean);
      }, "json");
    });
  });

});
