$(function () {
  window.request = function (url, data, after) {
    after = after || function () {};
    return $.post(url, Object.assign(data, window._token), after, 'json');
  };

  window.rub = function (num) {
    return Math.round(num).toLocaleString('ru-RU') + ' ₽';
  };

  window.goal = function (name) {
    if (!window.ym) return console.log('GOAL ERROR');
    console.log('GOAL: ' + name);
    ym(93065155, 'reachGoal', name)
  };

  if (location.href.indexOf('?') !== -1) {
    history.pushState({}, document.title, location.href.split('?')[0]);
  }

  window.isloading = 0;
  window.ImageX = function(element, src) {
    element.css({'backgroundImage': 'url("' + src + '")', 'opacity': 0});
    window.isloading += 1;
    let e = new Image();
    e.onload = () => {
      setTimeout(() => {
        element.addClass('bg-loaded').css({'opacity': 1});
        window.isloading -= 1;
      }, 250);
    };
    e.src = src;
  }

  $(document).on('click', '[data-form-action]', function (e) {
    e.preventDefault();
    let form = $(this).closest('form');
    window.goal($(this).data('form-action'));
    setTimeout(function () {
      form.submit();
    }, 350);
  });

  $(window).on('load', function () {
    let action = location.hash.split(":");
    if (action[0] != '#event') return;

    window.goal(action[1]);
    location.hash = '';
    setTimeout(function () {
      location.reload();
    }, 350);
  });

  $('[data-phone]').mask("+7 (999) 999-99-99", {
    completed: function () {
      $(this).addClass('completed');
    }
  });

  $(document).on('change keydown', '[data-action]', function () {
    goal(this.dataset['action']);
  });

  $(document).on('click', '[data-mm-toggle]', function () {
    $('body').toggleClass('mm-opened');
  });
});
