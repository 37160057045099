$(function () {
  $('[data-main]').each(function (){
    if ($(this).find('.image-full').length > 0) return ;
    let place = $('<div class="image-full"></div>');
    place.appendTo($(this));

    let img = $(this).data('main');
    if ($(window).width() <= window.sizes.S && $(this).data('mini')) img = $(this).data('mini');

    if ($(this).data('it') === 'self') {
      place = $(this);
    }
    // place.parent().append('<img class="hidden" src="'+img+'" />')

    window.ImageX(place, img);
  });
});

